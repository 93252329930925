import React from "react";
import { MasterLayoutTemplate } from "themes/common/layouts";

class MasterLayout extends React.Component {
    render() {
        return <MasterLayoutTemplate {...this.props} />;
    }
}

export default MasterLayout;
