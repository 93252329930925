import React from "react";
import PropTypes from "prop-types";
import { MrbNotificationContainer } from "mrb/notifications";

function MasterLayoutTemplate({ renderChildRoute, ...props }) {
    return (
        <React.Fragment>
            {renderChildRoute(props)}
            <MrbNotificationContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                closeOnClick
            />
        </React.Fragment>
    );
}

MasterLayoutTemplate.propTypes = {
    renderChildRoute: PropTypes.func.isRequired,
    props: PropTypes.any,
};

export default MasterLayoutTemplate;
