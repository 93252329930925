import React from "react";
import PropTypes from "prop-types";

function PublicLayoutTemplate({ renderChildRoute, ...props }) {
    return (
        <div className="l__public">
            <div className="c-membership__hero">
                <p className="c-tag c-tag--sml c-tag--rounded c-tag--inactive">
                    Some content displayed may require a Premium subscription
                </p>
            </div>

            {renderChildRoute(props)}
        </div>
    );
}

PublicLayoutTemplate.propTypes = {
    renderChildRoute: PropTypes.func,
};

export default PublicLayoutTemplate;
