import React from "react";
import PropTypes from "prop-types";
import { MainSidebar } from "common/components";
import { ContentUnavailableModal } from "application/common/components";
import { MrbProgressBarLoader } from "mrb/components/loader";
import { MrbConfirmDialog } from "mrb/components/modal";
import { mergeCss } from "mrb/common/utils";
import { defaultTemplate } from "common/hoc";

function MainLayoutTemplate({ renderChildRoute, routeStore, t }) {
    const { rootStore } = routeStore;
    const { menuStore } = rootStore;

    return (
        <div className={mergeCss("l__admin", menuStore.isOpen ? "is-open" : null)}>
            <MrbProgressBarLoader loaderStore={rootStore.mainLoaderStore} />
            <MainSidebar />
            <div className="l__admin--main">{renderChildRoute()}</div>
            <MrbConfirmDialog store={rootStore.confirmDialogStore} t={t} />
            <ContentUnavailableModal store={rootStore.userContentManagerStore} t={t} />
        </div>
    );
}

MainLayoutTemplate.propTypes = {
    renderChildRoute: PropTypes.func.isRequired,
    routeStore: PropTypes.object.isRequired,
};

export default defaultTemplate(MainLayoutTemplate);
